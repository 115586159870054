import { CardTextBox, Section } from 'views/style'
import { Box, Flex } from 'components/elements'
import { Button } from 'components/button'
import { useNavigate } from 'react-router-dom'
import { routes } from 'configs/constants'
import { Grid } from '@mui/material'
import { styled } from '@stitches/react'

const QuoteButton = () => {
  const navigate = useNavigate()
  return (
    <Section css={{ position: 'relative' }}>
      <QuoteBg>
        <Box css={BackgroundCss}>
          <Grid container item>
            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <img src="/images/Quote.png" alt="Quote Image" />
            </Grid>
            <Grid item xs={12} md={4} style={{ alignSelf: 'center' }}>
              <Box css={CardTextBox}>
                <Box as="h2" css={{ '@md': { fontSize: '2rem' } }}>
                  Contact Us!
                </Box>
                <Box as="span" css={{ 'color': '$white', 'mb': '3rem', '@md': { fontSize: '$xl', lineHeight: 1.5 } }}>
                  Answer a few questions about your environment to get started
                </Box>

                <Flex justify={'start'} css={{ mt: '$4' }}>
                  <Button type="submit" size="lg" onClick={() => navigate(routes.support)}>
                    Contact Us
                  </Button>
                </Flex>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </QuoteBg>
    </Section>
  )
}

export const BackgroundCss = {
  backgroundImage: "url('/images/Rectangle312.png')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '$base',
  padding: '1rem',
  width: '100%',
  margin: 'auto',
  boxShadow: '0px 4px 24px -1px rgba(0, 0, 0, 0.20)',
  backdropFilter: 'blur(25px)',
}
export const QuoteBg = styled('div', {
  borderRadius: '$base',
  padding: '2px',
  width: '90%',
  margin: 'auto',
  maxWidth: '1300px',
})
export default QuoteButton
